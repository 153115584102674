.jsonSchemaForm{
    color :#bad369 !important;
    min-width: 40vw;
}

legend{
    color: #bad369 !important;
}

.btn-info{ 
    background-color: #bad369;
    border: none;
}